@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'American';
  src: url('./assets/fonts/American\ Captain.ttf') format('woff2'),
       url('./assets/fonts/American\ Captain.ttf') format('woff');
  font-weight: normal;
  font-style:normal;

}
@font-face {
  font-family: 'century';
  src: url('./assets/fonts/centurygothic.ttf') format('woff2'),
       url('./assets/fonts/centurygothic.ttf') format('woff');
  font-weight: normal;
  font-style:normal;

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Send the video behind other components */
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire background */
}

.rotate-text {
  transform: rotate(90deg);
  transform-origin: right bottom; /* Adjusts the rotation point to align text to the right */
  margin-right: -50px; /* Adjust this value to fine-tune alignment */
}

/* Ensure that the text and the main content are on the same line */
.flex-1 {
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: space-between; /* Positions content on opposite sides */
}


@keyframes fadeInLeftToRight {
  from {
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.swiper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes fadeInRightToLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fadeInLeftToRight {
  animation: fadeInLeftToRight 1s ease-in-out forwards;
}

.animate-fadeInRightToLeft {
  animation: fadeInRightToLeft 1s ease-in-out forwards;
}
